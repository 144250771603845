.coming-soon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  text-align: center;
  padding: 20px;
}

.coming-soon-title {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.coming-soon-description {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 30px;
}

.coming-soon-image img {
  max-width: 90%;
  height: auto;
}
